<template>
  <baidu-map class="map" :center="{lng: 105.000, lat: 38.000}" :zoom="zoom">
    <bm-point-collection :points="points" shape="BMAP_POINT_SHAPE_STAR" color="red" size="BMAP_POINT_SIZE_SMALL" @click="clickHandler"></bm-point-collection>
    <button @click="zoomIn">+</button>
    <button @click="zoomOut">-</button>
  </baidu-map>
</template>

<script>
export default {
  name: "BdMapPoints",
  data () {
    return {
      points: [],
      zoom: 5,
    }
  },
  created() {
    this.addPoints ();
  },
  methods: {
    clickHandler (e) {
      alert(`单击点的坐标为：${e.point.lng}, ${e.point.lat}`);
    },
    addPoints () {
      const points = [];
      for (var i = 0; i < 500; i++) {
        const position = {lng: Math.random() * 40 + 85, lat: Math.random() * 30 + 21}
        points.push(position)
      }
      this.points = points
    },
    zoomIn() {
      this.zoom = this.zoom + 1;
    },
    zoomOut() {
      this.zoom = this.zoom - 1;
    }
  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 700px;
}
</style>